// ** React Imports
import { useContext } from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import IconContainer from "components/global/IconContainer";
import Block from "components/layout/Block";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const SIcon = styled.div`
	position: absolute;
	display: flex;
	margin: ${({ m }) => m}px;
	right: 0;
	top: 0;
	pointer-events: none;

	${({ full }) =>
		full &&
		css`
			width: 100%;
			height: 100%;
		`}

	${({ relative }) =>
		relative &&
		css`
			position: relative;
		`}

	${IconContainer} {
		display: block;

		border-radius: 100%;
		position: relative;

		${({ full }) =>
			full
				? css`
						width: 100%;
						height: 100%;
				  `
				: css`
						padding: ${({ p, h }) => p + (h / 2 || 0)}px
							${({ p, w }) => p + (w / 2 || 0)}px
							${({ p, h }) => p + (h / 2 || 0)}px
							${({ p, w }) => p + (w / 2 || 0)}px;
				  `}

		${({ noBackground }) =>
			!noBackground &&
			css`
				background-color: rgba(38, 38, 38, 0.6);
			`}
	}

	svg {
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		/* left: calc(50% - ${({ w }) => w / 2 || 0}px + ${({ ml }) =>
			ml || 0}px);
		top: calc(50% - ${({ h }) => h / 2 || 0}px + ${({ mb }) => mb || 0}px); */
		width: ${({ w }) => w || 0}px;
		height: ${({ h }) => h || 0}px;

		${({ withShadow }) =>
			withShadow &&
			css`
				filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
			`}
	}

	svg > path {
		fill: #f2f2f2;
	}
`;

// #####################################################

const StyledIcon = ({ size, mirror, children, ...props }) => {
	const { isPhone } = useContext(deviceTypeContext);

	// #####################################################

	switch (size) {
		case "camera": {
			return (
				<SIcon m={8} p={8} w={9} h={9} ml={1} {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);
		}

		case "masonry_small": {
			const iconSize = isPhone ? 24 : 30;
			return (
				<SIcon w={iconSize} h={iconSize} {...props}>
					<Block full style={{ paddingBottom: "19px" }}>
						<IconContainer mirror={mirror}>
							{children}
						</IconContainer>
					</Block>
				</SIcon>
			);
		}

		case "masonry": {
			const iconSize = isPhone ? 24 : 40;
			return (
				<SIcon w={iconSize} h={iconSize} {...props}>
					<Block full style={{ paddingBottom: "19px" }}>
						<IconContainer mirror={mirror}>
							{children}
						</IconContainer>
					</Block>
				</SIcon>
			);
		}

		case "large_post": {
			const iconSize = isPhone ? 40 : 60;
			return (
				<SIcon w={iconSize} h={iconSize} {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);
		}

		case "modal_video_center": {
			const iconSize = isPhone ? 60 : 80;
			return (
				<SIcon m={0} p={40} w={iconSize} h={iconSize} {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);
		}

		case "modal_thumbnail": {
			return (
				<SIcon w={24} h={24} {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);
		}

		case "userpanel":
			return (
				<SIcon w={24} h={24} {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);

		case "userpanel_small":
			return (
				<SIcon w={15} h={15} white {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);

		case "userpanel_edit_video": {
			const iconSize = isPhone ? 40 : 60;
			return (
				<SIcon p={30} w={iconSize} h={iconSize} {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);
		}

		case "userprofile_gallery":
			return (
				<SIcon w={24} h={24} {...props}>
					<IconContainer mirror={mirror}>{children}</IconContainer>
				</SIcon>
			);

		default:
			return (
				<SIcon w={20} h={20} {...props}>
					<IconContainer>{children}</IconContainer>
				</SIcon>
			);
	}
};

// #####################################################

export default StyledIcon;

// #####################################################
