import styled, { css } from "styled-components";

const IconContainer = styled.span.attrs((props) => ({
	spin: props.spin,
	rotate: props.rotate,
	miror: props.mirror,
}))`
	display: inline-block;

	svg {
		display: inline-block;
		// vertical-align: middle;
		vertical-align: -0.125em;
		height: 1em;
		width: 1em;

		${({ spin, rotate }) =>
			spin &&
			css`
				animation: ${rotate} 2s linear infinite;
			`}

		${({ mirror }) =>
			mirror &&
			css`
				transform: rotateY(180deg);
			`}
	}
`;

export default IconContainer;
